


















































































































































































































































































































































































































































































import { icon } from '@fortawesome/fontawesome-svg-core';
import { timeStamp } from 'console';
import gql from 'graphql-tag';
import { update } from 'lodash';
import { i } from 'mathjs';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import {
  Category,
  Connection,
  Inventory,
  InventoryGroup,
  inventoryIdToGlAccountId,
  Maybe,
  Providers,
  Wallet,
  WalletIdToInventoryId,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { store } from '@/store';
import { InventoryActionTypes } from '@/store/modules/inventories/types';
import { isAccountingProvider } from '@/utils/accountingProviders';

@Component({ components: { UiSelect2, UiButton, UiModal, UiTextEdit } })
export default class InventoryManagement extends BaseVue {
  public walletsStyle = 'tw-p-1 tw-grid tw-gap-2 tw-h-40 tw-content-start tw-overflow-y-scroll';
  public walletStyle = 'tw-p-2 tw-shadow-md tw-rounded-md';
  // == state ==
  public creatingInventoryGroup = false;
  public creatingInventory = false;
  public addingAccountingInventoryGroup = false;
  public movingAccountingWallet = false;

  public changesMade = false;
  public glAssignmentChangesMade = false;

  public savingWalletIdsToInventoryIds = false;
  public settingInventoryGroupConnection = false;
  public settingGlAccountAssignment = false;

  public readonly defaultInventory = { name: '', description: '' };
  public newInventory = this.defaultInventory;
  public selectedInventory: Inventory = {
    orgId: '',
    inventoryGroupId: '',
    name: 'default',
    description: '',
  };

  public readonly defaultInventoryGroup = { name: '', description: '' };
  public newInventoryGroup = this.defaultInventoryGroup;
  public selectedInventoryGroup: InventoryGroup = {
    orgId: '',
    walletIdsToInventoryIds: [],
    name: '',
    description: '',
  };

  public inventorySearch = '';
  public defaultSearch = '';

  public selectedDefaultWallets: { [key: string]: Wallet | undefined } = {};
  public selectedInventoryWallets: { [key: string]: Wallet | undefined } = {};
  public selectedDefaultCategories: { [key: string]: Wallet | undefined } = {};

  public walletIdsToInventoryIds: { [key: string]: string | undefined } = {};
  public oldWalletIdsToInventoryIds: { [key: string]: string | undefined } = {};

  public connections: any[] = [];
  public selectedConnection: Partial<Connection> = {};

  public selectedCategory: Partial<Category> = {};
  public selectedUnassignedInventories: Array<string> = [];
  public inventoryIdToGlAccountIds: { [key: string]: string | undefined } = {};
  public oldInventoryIdToGlAccountIds: { [key: string]: string | undefined } = {};

  public savingAccountingConnection = false;
  public savingInventoryGroup = false;
  public savingAccountingGroup = false;
  public savingInventory = false;
  public savingGlAccountAssignment = false;

  selectAllDefaultWallets() {
    if (!this.allDefaultWalletsSelected) {
      this.selectedDefaultWallets = {};
      this.defaultWallets.forEach((wallet) => {
        if (wallet.id) {
          this.selectedDefaultWallets[wallet.id] = wallet;
        }
      });
    } else {
      this.selectedDefaultWallets = {};
    }
  }

  selectAllInventoryWallets() {
    if (!this.allInventoryWalletsSelected) {
      this.selectedInventoryWallets = {};
      this.inventoryWallets.forEach((wallet) => {
        if (wallet.id) {
          this.selectedInventoryWallets[wallet.id] = wallet;
        }
      });
    } else {
      this.selectedInventoryWallets = {};
    }
  }

  selectAllDefaultInventories() {
    if (!this.allDefaultInventoriesSelected) {
      this.selectedUnassignedInventories = [];

      this.unassignedInventories.forEach((inventory) => {
        if (inventory.id) {
          this.selectedUnassignedInventories.push(inventory.id);
        }
      });
    } else {
      this.selectedUnassignedInventories = [];
    }
  }

  handleSetAccountingConnection(connection: Connection) {
    this.selectedConnection = connection;
  }
  // == hooks ==

  mounted() {
    this.refreshInventoriesAndGroups();
    this.refreshConnections();
  }

  // == helpers ==
  clearSelected() {
    this.selectedDefaultWallets = {};
    this.selectedInventoryWallets = {};
  }

  async undoChanges() {
    await this.refreshInventoriesAndGroups();
    this.walletIdsToInventoryIds = this.oldWalletIdsToInventoryIds;
    this.changesMade = false;
  }

  /**
   *
   */
  public async moveSelectedToInventory() {
    const newMap = { ...this.walletIdsToInventoryIds };
    Object.values(this.selectedDefaultWallets).map((wallet) => {
      if (wallet?.id && this.selectedInventory.id) {
        newMap[wallet.id] = this.selectedInventory.id;
      }
    });
    this.walletIdsToInventoryIds = newMap;
    this.changesMade = true;
    this.clearSelected();
  }

  /**
   *
   */
  public async moveSelectedToDefault() {
    const newMap = { ...this.walletIdsToInventoryIds };
    Object.values(this.selectedInventoryWallets).map((wallet) => {
      if (wallet?.id && this.selectedInventory.id) {
        newMap[wallet.id] = undefined;
      }
    });
    this.walletIdsToInventoryIds = newMap;
    this.changesMade = true;
    this.clearSelected();
  }

  /**
   *
   * @param id
   */
  public deselectDefaultWallet(id: string) {
    const newObj = { ...this.selectedDefaultWallets };
    delete newObj[id];

    this.selectedDefaultWallets = newObj;
  }

  /**
   *logt
   */
  public selectDefaultWallet(id: string, wallet: Wallet) {
    if (wallet.id) this.selectedDefaultWallets = { ...this.selectedDefaultWallets, [wallet.id]: wallet };
  }

  public toggleSelectUnassignedInventory(id?: Maybe<string> | undefined) {
    if (!id) return;
    let updateObj = this.selectedUnassignedInventories;

    if (updateObj.indexOf(id) >= 0) {
      updateObj = updateObj.filter((updateId) => updateId !== id);
    } else {
      updateObj.push(id);
    }
    this.selectedUnassignedInventories = updateObj;
  }

  public moveUnassignedInventories() {
    const updateObj: { [key: string]: string | undefined } = { ...this.inventoryIdToGlAccountIds };
    this.selectedUnassignedInventories.forEach((unassignedInventoryId) => {
      if (this.selectedCategory === undefined) return;

      updateObj[unassignedInventoryId] = this.selectedCategory.id;
    });
    this.inventoryIdToGlAccountIds = updateObj;
    this.clearSelectedUnassignedInventories();
    this.glAssignmentChangesMade = true;
  }

  public undoGlAssignmentChanges() {
    this.inventoryIdToGlAccountIds = this.oldInventoryIdToGlAccountIds;
    this.clearSelectedUnassignedInventories();
    this.glAssignmentChangesMade = false;
  }

  public clearSelectedUnassignedInventories() {
    this.selectedUnassignedInventories = [];
  }

  /**
   *
   * @param id
   * @param wallet
   */
  public selectInventoryWallet(id: string, wallet: Wallet) {
    if (wallet.id) this.selectedInventoryWallets = { ...this.selectedInventoryWallets, [wallet.id]: wallet };
  }

  /**
   *
   */
  public deselectInventoryWallet(id: string) {
    const newWalletMap = { ...this.selectedInventoryWallets };
    delete newWalletMap[id];

    this.selectedInventoryWallets = newWalletMap;
  }

  // == handlers ==
  public handleMoveAccountingWallet() {
    this.movingAccountingWallet = true;
  }

  public handleCloseMoveAccountingWallet() {
    this.movingAccountingWallet = false;
  }

  public handleClickSetAccountingInventoryGroup() {
    this.addingAccountingInventoryGroup = true;
  }

  public handleClickSaveGlAssignment() {
    this.settingGlAccountAssignment = true;
  }

  public async handleAddAccountingInventoryGroup() {
    try {
      this.savingAccountingGroup = true;
      const mutation = gql`
        mutation updateOrg($orgId: ID!, $newAccountingInventoryGroupIds: [String]) {
          updateOrg(orgId: $orgId, accountingInventoryGroupIds: $newAccountingInventoryGroupIds) {
            id
          }
        }
      `;
      const variables = {
        orgId: this.$store.state.currentOrg.id,
        newAccountingInventoryGroupIds: Array.from(
          new Set([...this.$store.state.currentOrg.accountingInventoryGroupIds, this.selectedInventoryGroup.id])
        ),
      };
      const resp = await this.$apollo.mutate({ mutation, variables });
      await this.refreshInventoriesAndGroups();
      this.addingAccountingInventoryGroup = false;
    } catch (error) {
    } finally {
      this.savingAccountingGroup = false;
    }
  }

  public isUnassignedInventorySelected(id?: Maybe<string> | undefined) {
    if (!id) return false;
    return this.selectedUnassignedInventories.find((selectedId) => selectedId === id) !== undefined;
  }

  /** @deprecated */
  public async saveAccountingInventoryGroup() {
    const resp = await this.$apollo.mutate({
      mutation: gql`
        mutation updateOrg($orgId: ID!, $accountingInventoryGroupId: String!) {
          updateOrg(orgId: $orgId, accountingInventoryGroupId: $accountingInventoryGroupId) {
            id
          }
        }
      `,
      variables: {
        orgId: this.$store.state.currentOrg.id,
        accountingInventoryGroupId: this.selectedInventoryGroup.id,
      },
    });

    await this.refreshInventoriesAndGroups();
    this.addingAccountingInventoryGroup = false;
  }

  public async refreshConnections() {
    try {
      const query = gql`
        query connections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            name
            provider
            isDeleted
          }
        }
      `;
      const variables = {
        orgId: this.$store.state.currentOrg.id,
      };

      const response = await this.$apollo.query({ query, variables, fetchPolicy: 'network-only' });

      const connectionsResponse: Connection[] = response.data?.connections;

      if (connectionsResponse) {
        this.connections.push(
          ...response.data.connections
            .filter((c: Connection) => !c.isDeleted && isAccountingProvider(c.provider))
            .map((c: Connection) => ({
              ...c,
              name: c.name || c.provider, // Use provider as fallback for name
            }))
        );

        // Add manual connection if necessary
        const categories: Category[] = this.$store.getters['categories/CATEGORIES'];
        const manualCategories = categories.filter(
          (c: Category) => c.accountingConnectionId === 'Manual' || c.source === 'Manual'
        );
        if (manualCategories.length > 0) {
          this.connections.push({
            id: 'Manual',
            name: 'Bitwave',
            provider: Providers.Manual,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  }

  public handleCloseSetAccountingInventoryGroup() {
    this.addingAccountingInventoryGroup = false;
  }

  public handleSelectDefaultWallet(id: string) {
    const wallet = this.wallets.find((wallet: Wallet) => wallet.id === id);

    if (wallet) {
      if (!this.selectedDefaultWallets[id]) this.selectDefaultWallet(id, wallet);
      else this.deselectDefaultWallet(id);
    }
  }

  public handleSelectCategory(category: Category) {
    this.selectedCategory = category;
  }

  public handleSelectInventoryWallet(id: string) {
    const wallet = this.wallets.find((wallet: Wallet) => wallet.id === id);

    if (wallet) {
      if (!this.selectedInventoryWallets[id]) this.selectInventoryWallet(id, wallet);
      else this.deselectInventoryWallet(id);
    }
  }

  public async handleSelectInventoryGroup(inventoryGroup: InventoryGroup) {
    const foundGroup = this.inventoryGroups.find((group) => group.id === inventoryGroup.id);
    if (!foundGroup) {
      return;
    }
    this.selectedInventoryGroup = foundGroup;

    if (!this.selectedInventoryGroup.id) {
      return;
    }

    const newWalletIdsToInventoryIdsObject: { [key: string]: string } = {};
    const newInventoryIdToGlAccountIdsObject: { [key: string]: string } = {};

    if (foundGroup) {
      if (foundGroup.walletIdsToInventoryIds) {
        foundGroup.walletIdsToInventoryIds.forEach(
          (pair: WalletIdToInventoryId) => (newWalletIdsToInventoryIdsObject[pair.walletId] = pair.inventoryId)
        );
      }

      if (foundGroup.inventoryIdsToGlAccountIds) {
        foundGroup.inventoryIdsToGlAccountIds.forEach(
          (pair: inventoryIdToGlAccountId) => (newInventoryIdToGlAccountIdsObject[pair.inventoryId] = pair.glAccountId)
        );
      }
    }

    this.walletIdsToInventoryIds = newWalletIdsToInventoryIdsObject;
    this.oldWalletIdsToInventoryIds = this.walletIdsToInventoryIds;

    this.inventoryIdToGlAccountIds = newInventoryIdToGlAccountIdsObject;
    this.oldInventoryIdToGlAccountIds = this.inventoryIdToGlAccountIds;

    this.selectedInventory = {
      orgId: '',
      inventoryGroupId: '',
      name: 'default',
      description: '',
    };
  }

  public handleSelectInventory(inventory: Inventory) {
    this.selectedInventory = inventory;
  }

  public handleCloseCreateGroup() {
    this.creatingInventoryGroup = false;
  }

  public handleClickCreateGroup() {
    this.creatingInventoryGroup = true;
  }

  public handleCloseCreateInventory() {
    this.creatingInventory = false;
  }

  public handleClickCreateInventory() {
    this.creatingInventory = true;
  }

  public async handleClickSaveInventoryGroup() {
    await this.saveInventoryGroup();
    this.newInventoryGroup = this.defaultInventoryGroup;
    this.creatingInventoryGroup = false;
  }

  public async handleClickSaveInventory() {
    try {
      this.savingInventory = true;
      await this.saveInventory();
      await this.refreshInventoriesAndGroups();
      if (this.selectedInventoryGroup.id) {
        const walletPairObject: { [key: string]: string } = {};
        const selectedGroup = this.inventoryGroupsMap.get(this.selectedInventoryGroup.id);
        if (!(selectedGroup && selectedGroup.walletIdsToInventoryIds)) {
          throw new Error(`Unable to save inventory. Group not defined with id ${this.selectedInventoryGroup.id}`);
        }
        selectedGroup.walletIdsToInventoryIds.forEach((pair: { walletId: string; inventoryId: string }) => {
          walletPairObject[pair.walletId] = pair.inventoryId;
        });
        this.walletIdsToInventoryIds = walletPairObject;
      }
      this.newInventory = this.defaultInventory;
      this.creatingInventory = false;
      this.clearSelected();
    } catch (error) {
    } finally {
      this.savingInventory = false;
    }
  }

  // == actions ==
  async refreshInventoriesAndGroups() {
    await store.dispatch('updateOrgs');
    await store.dispatch('inventories/' + InventoryActionTypes.GET_INVENTORIES, { orgId: store.state.currentOrg.id });
  }

  async saveInventory() {
    await store.dispatch('inventories/' + InventoryActionTypes.CREATE_INVENTORY, {
      orgId: store.state.currentOrg.id,
      inventoryGroupId: this.selectedInventoryGroup.id,
      name: this.newInventory.name,
      description: this.newInventory.description,
      wallets: Object.values(this.selectedDefaultWallets).map((wallet) => wallet?.id),
      walletIdsToInventoryIds: this.walletIdsToInventoryIds,
    });
    await this.refreshInventoriesAndGroups();
  }

  async saveInventoryGroup() {
    try {
      this.savingInventoryGroup = true;
      await store.dispatch('inventories/' + InventoryActionTypes.CREATE_INVENTORY_GROUP, {
        name: this.newInventoryGroup.name,
        description: this.newInventoryGroup.description,
        orgId: store.state.currentOrg.id,
      });
    } catch (error) {
    } finally {
      this.savingInventoryGroup = false;
    }
  }

  public async saveAccountingConnection() {
    try {
      this.savingAccountingConnection = true;
      const update: any = {
        orgId: store.state.currentOrg.id,
        inventoryGroupId: this.selectedInventoryGroup.id,
        accountingConnectionIds: new Set([this.selectedConnection.id]),
      }; // send existing map so changes aren't accidentally made

      await store.dispatch('inventories/' + InventoryActionTypes.UPDATE_INVENTORY_GROUP, update);
      await this.refreshInventoriesAndGroups();
      const selectedGroup = this.inventoryGroups.find((newGroup) => newGroup.id === this.selectedInventoryGroup.id);
      if (selectedGroup) {
        this.selectedInventoryGroup = selectedGroup;
      }
      this.settingInventoryGroupConnection = false;
    } catch (error) {
    } finally {
      this.savingAccountingConnection = false;
    }
  }

  async saveWalletIdsToInventoryGroupIds() {
    try {
      this.savingWalletIdsToInventoryIds = true;
      const update: any = {
        orgId: store.state.currentOrg.id,
        inventoryGroupId: this.selectedInventoryGroup.id,
        walletIdsToInventoryIds: this.walletIdsToInventoryIds,
      };

      await store.dispatch('inventories/' + InventoryActionTypes.UPDATE_INVENTORY_GROUP, update);
      await this.refreshInventoriesAndGroups();
      this.clearSelected();
    } catch (error) {
    } finally {
      this.savingWalletIdsToInventoryIds = false;
    }
  }

  async saveInventoryIdsToGlAccountIds() {
    try {
      this.savingGlAccountAssignment = true;
      const update: any = {
        orgId: store.state.currentOrg.id,
        inventoryGroupId: this.selectedInventoryGroup.id,
        inventoryIdsToGlAccountIds: this.inventoryIdToGlAccountIds,
      };

      await store.dispatch('inventories/' + InventoryActionTypes.UPDATE_INVENTORY_GROUP, update);
      await this.refreshInventoriesAndGroups();
      this.clearSelectedUnassignedInventories();
      this.settingGlAccountAssignment = false;
      this.glAssignmentChangesMade = false;
    } catch (error) {
    } finally {
      this.savingGlAccountAssignment = false;
    }
  }

  // == getters ==
  /**
   *
   */
  get wallets(): Wallet[] {
    return store.getters['wallets/WALLETS'];
  }

  get inventoryWallets(): Wallet[] {
    if (this.selectedInventory.id)
      return this.wallets
        .filter((wallet: any) => {
          if (wallet) return this.walletIdsToInventoryIds[wallet.id] === this.selectedInventory.id;
        })
        .filter((wallet) => wallet.name?.toLowerCase().includes(this.inventorySearch));
    else return [];
  }

  get defaultWallets(): Wallet[] {
    if (this.selectedInventoryGroup.id)
      return this.wallets

        .filter((wallet) => {
          if (!wallet.id) return false;
          if (wallet) return this.walletIdsToInventoryIds[wallet.id] === undefined;
        })
        .filter((wallet) => wallet.name?.toLowerCase().includes(this.defaultSearch));
    return [];
  }

  /**
   *
   */
  get inventoryGroups(): InventoryGroup[] {
    return (
      store.getters['inventories/INVENTORY_GROUPS']?.map((inventoryGroup: any) => ({
        ...inventoryGroup,
        name: this.isAccountingInventoryGroup(inventoryGroup.id)
          ? inventoryGroup.name + ' (Primary)'
          : inventoryGroup.name,
      })) ?? []
    );
  }

  isAccountingInventoryGroup(inventoryGroupId: string): boolean {
    return new Set(this.$store.state.currentOrg.accountingInventoryGroupIds).has(inventoryGroupId);
  }

  /**
   *
   */
  get inventories(): Inventory[] {
    return (
      store.getters['inventories/INVENTORIES']
        ?.filter((inventory: any) => inventory.inventoryGroupId === this.selectedInventoryGroup.id)
        .map((inventory: any) => ({
          ...inventory,
          label: inventory.name,
        })) ?? []
    );
  }

  get accountingInventoryGroups() {
    const accountingInventoryGroupIds = new Set(store.state.currentOrg.accountingInventoryIds);
    const accountingInventoryGroups = store.getters['inventories/INVENTORY_GROUPS'].filter((inventoryGroup: any) =>
      accountingInventoryGroupIds.has(inventoryGroup.id)
    );
    return accountingInventoryGroups;
  }

  get inventoryGroupsMap() {
    const map = new Map<string, InventoryGroup>();
    this.inventoryGroups.map((group) => {
      if (group.id) map.set(group.id, group);
    });
    return map;
  }

  get inventoriesMap() {
    const map = new Map();
    this.inventories.map((inventory: any) => map.set(inventory.id, inventory));
    return map;
  }

  /**
   *
   */
  get primaryGroupSelected() {
    return new Set(store.state.currentOrg.accountingInventoryGroupIds).has(this.selectedInventoryGroup.id);
  }

  get categories(): Category[] {
    return store.getters['categories/CATEGORIES'];
  }

  get filteredCategories() {
    return this.categories.filter((category) => category.accountingConnectionId === this.selectedConnection.id);
  }

  get unassignedInventories() {
    return this.inventories.filter((invObj) => {
      if (!invObj.id) return false;
      return this.inventoryIdToGlAccountIds[invObj.id] === undefined;
    });
  }

  get assignedInventories() {
    return this.inventories.filter((invObj) => {
      if (!invObj.id) return false;
      return this.inventoryIdToGlAccountIds[invObj.id] !== undefined;
    });
  }

  get allDefaultInventoriesSelected() {
    const selectedUnassignedIds = new Set(this.selectedUnassignedInventories);
    const unassignedIds = new Set(this.unassignedInventories.map((inventory) => inventory.id));

    if (unassignedIds.size === 0) return false;
    return [...unassignedIds].every((unassignedId) => {
      if (!unassignedId) return false;
      else return selectedUnassignedIds.has(unassignedId);
    });
  }

  get allDefaultWalletsSelected() {
    const selectedUnassignedIds = new Set(
      Object.values(this.selectedDefaultWallets).map((wallet) => {
        if (wallet) {
          return wallet.id;
        }
      })
    );

    const unassignedIds = new Set(
      this.defaultWallets.map((wallet) => {
        return wallet.id;
      })
    );

    if (unassignedIds.size === 0) return false;
    return [...unassignedIds].every((unassignedId) => {
      if (!unassignedId) return false;
      else return selectedUnassignedIds.has(unassignedId);
    });
  }

  get allInventoryWalletsSelected() {
    const selectedUnassignedIds = new Set(
      Object.values(this.selectedInventoryWallets).map((wallet) => {
        if (wallet) {
          return wallet.id;
        }
      })
    );

    const unassignedIds = new Set(
      this.inventoryWallets.map((wallet) => {
        return wallet.id;
      })
    );

    if (unassignedIds.size === 0) return false;
    return [...unassignedIds].every((unassignedId) => {
      if (!unassignedId) return false;
      else return selectedUnassignedIds.has(unassignedId);
    });
  }

  @Watch('selectedInventoryGroup')
  onInventoryGroupChange() {
    if (!this.selectedInventoryGroup) return;
    const connectionIds = this.selectedInventoryGroup.accountingConnectionIds;
    if (connectionIds) {
      const existingGroupCollectionId = connectionIds[0];

      const foundConnection = this.connections.find((connection) => connection.id === existingGroupCollectionId);

      if (foundConnection) {
        this.selectedConnection = foundConnection;
      }
      // need to check if the user selected a Manual Bitwave connection - won't be found in the connections list
      // because the connection is not saved in the database
      else if (existingGroupCollectionId === 'Manual') {
        this.selectedConnection = {
          id: 'Manual',
          name: 'Bitwave',
          provider: Providers.Manual,
        };
      } else {
        this.selectedConnection = {};
      }
    } else {
      this.selectedConnection = {};
    }
  }
}
